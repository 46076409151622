import React from "react";
import '../../App.css';
import PriceCard from "../PriceCard";
import planData from '../data/tier-plan.json';

export default function Subscribe() {
    const textStyle = { width: "100%", padding:"0 15vw 30px 15vw", display: "flex", justifyContent:"center", color:"mediumslateblue"};
    return (
        <>
            <h1 className='subscribe'>Join Now</h1>
            <div style={{ background:"mistyrose"}}>
                <div style={{
                    display: "flex",
                    padding: "50px 0 0 0",
                    justifyContent: "center",
                    alignContent: "center",
                    height: "100%",
                    flexFlow: "column",
                    alignItems: "center",
                    maxWidth: "1500px",
                    margin: "0 auto",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}>
                    {planData.map(item => {
                        return (
                            <PriceCard
                                color1={item.color1}
                                color2={item.color2}
                                icon={item.icon}
                                price={item.price}
                                title={item.title}
                                link={item.link}
                                benifits={item.benifits}
                                buttonText={item.buttonText}
                            />
                        );
                    })}
                </div>
                <a style={textStyle}>*All content that is not allowed to be posted on Onlyfans (for example: anonymous models, poppers training, downgrading, etc)</a>
            </div>
        </>)
}