import React from 'react';
import Cards from '../Cards';
import '../../App.css';

export default function Gallery() {
    return (
        <>
            <h1 className='gallery'>Gallery</h1>
            <div className='gallery-body'>
                <Cards />
            </div>
        </>
    )
}