import React from 'react';
import { InfoColumn, InfoRow, InfoSec, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img, ApplicationLink } from './InfoSection.elements';
import { Container } from './applyPageStyles';
import '../Button.css';
//https://www.codavilla.com/posts/react-website-using-styled-components

const InfoSection = ({ lightBg, imgStart, lightTopLine, lightText, lightTextDesc, buttonLabel, description, headLine, topLine, buttonPath, img, alt, start }) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                {description.map(item => {
                                    return (
                                        <Subtitle lightTextDesc={lightTextDesc}>{item}</Subtitle>
                                    );
                                })}
                                {/* <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle> */}
                                {/* <Link to={buttonPath}> */}
                                {buttonLabel ? <>
                                    <button
                                        className={'btn btn--primary'}
                                        style={{margin:'0 10px 20px 0'}}
                                    >
                                        <ApplicationLink href={buttonPath[0]} target="_blank" rel="noreferrer">{buttonLabel[0]}</ApplicationLink>
                                    </button>
                                    {/* <div style={{margin:'15px 0px 15px 55px'}}>&nbsp;&nbsp;&nbsp;&nbsp;- OR -&nbsp;&nbsp;&nbsp;&nbsp;</div> */}
                                    <button
                                        className={'btn btn--primary'}
                                        style={{ margin: '0 10px 20px 0' }}
                                    >
                                        <ApplicationLink href={buttonPath[1]} target="_blank" rel="noreferrer">{buttonLabel[1]}</ApplicationLink>
                                    </button>
                                </> : <></>}
                                {/* </Link> */}
                                
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt}></Img>
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection
