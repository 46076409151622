import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Home () {
    // const url = 'https://video.twimg.com/ext_tw_video/1421319267178274816/pu/vid/480x852/QlNRWfabLxiVqUp4.mp4';
    
    return (
        <>
            <HeroSection />
        </>
    )
}

export default Home;