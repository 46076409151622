import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      {/* <div class='footer-links'> */}
        {/* <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>BASIC PLAN - $9.99/month</h2>
            <a
              class='social-icon-link cash'
              href='https://onlyfans.com/edgeu1'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='onlyfans'
            >
              <Button>Onlyfans</Button>
            </a>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>PREMIUM PLAN - $14.99/month</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div> */}
        {/* <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div> */}
      {/* </div> */}
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              EDGEU
              <img className='logo' src='/images/edgeu-logo.svg' alt='' />
            </Link>
          </div>
          <small class='website-rights'>Copyright © 2021 EDGEU - All Rights Reserved.</small>
          <div class='social-icons'>
            <a
              class='social-icon-link cash'
              href='https://cash.app/$EDGEU1'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='CashApp'
            >
              <i class='fas fa-dollar-sign' />
            </a>
            <a
              class='social-icon-link telegram'
              href='https://t.me/edgeu1'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='Telegram'
            >
              <i class='fab fa-telegram' />
            </a>
            <a
              class='social-icon-link snapchat'
              href='https://www.snapchat.com/add/edgeyou'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='Snapchat'
            >
              <i class='fab fa-snapchat' />
            </a>
            <a
              class='social-icon-link twitter'
              href='https://twitter.com/EDGEU1'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </a>
            <a
              class='social-icon-link email'
              href='mailto:edgingu@gmail.com'
              rel="noopener noreferrer"
              target='_blank'
              aria-label='Email'
            >
              <i class='fas fa-envelope' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;