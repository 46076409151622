export const homeObjOne = {
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    description: ['1. Filming anonymously. No face will be shown. I can cover your body marks and tattoos if they are not too big, and I can change your voice in the video',
        '2. Getting verified on Onlyfans so I don\'t need your ID to confirm your age or your signed consent letter to post video there: A third party confidential program will require your ID and face scan which is completely discreet'],
    headLine: 'Make sure you are okay with...',
    topLine: 'STEP I',
    img: 'images/infoImg/approve.svg',
    alt: 'Image not loading',
    start: ''
}

export const homeObjTwo = {
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    description: ['DM me on Twitter (@edgeu1) or email me (edgingu@gmail.com) your recent full nudes and wait for my response before moving to the next step.',
        'Your pictures must be clear and show your full body and boner. No catfish or pictures from months/years ago(verification pictures will be required at step 4)'],
    headLine: 'Send Me Your Recent Picutures',
    topLine: 'STEP II',
    img: 'images/infoImg/photos.svg',
    alt: 'Image not loading',
    start: ''
}

export const homeObjThree = {
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    description: ['1. Create an Onlyfans account', '2. Click \'Add Bank\' tab;', '3. Click \'Complete Verification\';'],
    headLine: 'Complete Verification',
    topLine: 'STEP III',
    img: 'images/infoImg/online_ad.svg',
    alt: 'Image not loading',
    start: ''
}

export const homeObjFour = {
    lightBg: true,
    primary: true,
    imgStart: 'start',
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    buttonLabel: ["Apply for an edging session", "Apply to be a remote sub"],
    description: ['Once your Onlyfans verification is approved, you can fill out one of the Google form applications listed below.'],
    headLine: 'Apply',
    topLine: 'STEP IV',
    buttonPath: ['https://forms.gle/awsvyX5irks44kAT8', 'https://forms.gle/o1nNtsg34kdYJLi26'],
    img: 'images/infoImg/forms.svg',
    alt: 'Image not loading',
    start: ''
}