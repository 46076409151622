// color1 = #00dbde
// color2 = #fc00ff
// import React from 'react';
import styled, { css } from 'styled-components';

const gradient = (degs, color1, color2) => css`
    background:
        linear-gradient(
            ${degs || 130}deg,
            #00dbde 0%,
            #fc00ff 100%
        );
`;

export const PriceCardContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    margin: 10px;
    width: 348px;
    min-height: 440px;
    padding: 2rem 0;
    border-radius: 0.5rem;
    color: white;
    flex-direction: row;
    flex-wrap: wrap;
    ${gradient()};
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.025),
                0 9px 46px 8px rgba(0,0,0,0.025),
                0 11px 15px -7px rgba(0,0,0,0.025);

    &::after {
        content: "";
        position: absolute;
        display: block;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        ${gradient(-50)};
        transition: opacity 0.75s;
    }
    &:hover::after { opacity: 1; }
`;

export const PriceContent = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
`;

export const PlanIcon = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width:50px;
    height: 50px;
    flex: 0 0 auto;
    margin-bottom: 0.8rem;
    border-radius: 5%;
    font-size: 30px;
    color: white;
    ${gradient()};
    box-shadow: 0 11px 15px -7px rgba(0,0,0,0.25);
`;
export const PlanTitle = styled.div`
    font-size:1.5rem;
`;

export const PlanCost = styled.div`
    font-size: 2rem;
`;

export const FeatureList = styled.div`
    justify-content: center;
    margin: 5px 10%;

`
export const FeatureListItem = styled.div`
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    margin: 0.7rem;
    /* text-shadow: 1px 2px 5px #121212; */
    & > i {
        font-size: 20px;
        margin-right: 0.5rem;
    }
    span {
        font-size: 18px;
    }
`;
export const ActionButtonContainer = styled.div`
    width: 100%;
    justify-content: center;
    text-align: center;
    bottom: 25px;
    display: flex;
    position: absolute;
`;

export const ActionButton = styled.div`
    flex: 0 0 auto;
    height: 40px;
    padding: 0 2rem;
    border: 0;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.85);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 11px 15px -7px rgba(0,0,0,0.25);
    transition: background 0.25s;
    font-family: sans-serif;
    line-height: 40px;
    text-align: center;
    z-index: 2;
    &:hover{background: rgba(255, 255, 255, 1);}
`;

export const BackgroundSquare = styled.div`
    position: absolute;
    z-index: 2;
    top: 52%;
    left: 0%;
    width: 200%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transform: rotate(-3deg);
`;