import React from 'react';
export const CardItem = ({ src, onClick, label, trailer, premium }) => {
    const trailorTag = trailer ? <div className='far fa-play-circle cards__item__trailor' /> : null;
    const premiumTag = premium ? <div className='cards__item__premium'>{premium}</div> : null;

    return (
        <div>
            <li className='cards__item'>
                <div className='cards__item__link' onClick={onClick}>
                    <figure className='cards__item__pic-wrap' data-category={label} >
                        {trailorTag}
                        {premiumTag}
                        <img src={src} alt='img_not_loading' className='cards__item__img' />
                    </figure>
                </div>
            </li>
        </div>
    )
};

export default CardItem