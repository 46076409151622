import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const useContainerDimensions = myRef => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions())
      }

      if (myRef.current) {
        setDimensions(getDimensions())
      }

      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])

    return dimensions;
  };
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const videoSrc = width > height ? '../images/gif-h.gif' : '../images/gif-v.gif';
  return (
    <div className='hero-container' ref={componentRef}>
      <img className="homepage-video-bg" src={videoSrc} />
      <h1>LOVE EDGING?</h1>
      <p>What are you waiting for?</p>
      <div className="hero-btns">
        <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>
          JOIN NOW
        </Button>
        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' buttonPath='/gallery'>
          GALLERY <i className='far fa-images' />
        </Button>
      </div>
    </div>
  )
}

export default HeroSection
