import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import Apply from './components/pages/Apply';
// import QnA from './components/pages/QnA';
import Subscribe from './components/pages/Subscribe';
import Footer from './components/Footer';
import Gallery from './components/pages/Gallery';
import ScrollToTop from './components/ScrollToTop';
import StartPage from './components/pages/StartPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/index.html' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/subscribe' element={<Subscribe />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/apply' element={<Apply />} />
          {/* <Route path='/q-n-a' element={<QnA />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;