import React from 'react';
import { PriceCardContainer, PriceContent, PlanIcon, PlanTitle, PlanCost, FeatureList, FeatureListItem, ActionButtonContainer, ActionButton, BackgroundSquare } from './PriceCardStyles.js';
const PriceCard = ({ color1, color2, price, title, link, icon, benifits, buttonText }) => {
    return (
        <div>
            <PriceCardContainer>
                <BackgroundSquare />
                <PriceContent>
                    <PlanIcon className={icon} />
                    <PlanTitle>{title}</PlanTitle>
                    <PlanCost>{price}</PlanCost>
                    <FeatureList>
                        {
                            benifits.map(item => (
                                <FeatureListItem>
                                    <i className="fas fa-check"/>
                                    <span style={{fontFamily:"sans-serif"}}>{item}</span>
                                </FeatureListItem>
                            ))
                        }
                    </FeatureList>
                    
                </PriceContent>
                <ActionButtonContainer>
                    <ActionButton><a href={link} style={{textDecoration:"none",color:"slateblue"}} target="_blank" rel="noreferrer">{buttonText}</a></ActionButton>
                </ActionButtonContainer>
            </PriceCardContainer>
        </div>
    )
}

export default PriceCard
