import React from "react";
import '../../App.css';
import InfoSection from "../InfoSection/InfoSection";
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from "../data/info-section-data";

export default function Apply() {
    return (
        <>
            <h1 className='apply'>APPLY</h1>
            {/* <StepCard></StepCard> */}
            <InfoSection {...homeObjOne}></InfoSection>
            <InfoSection {...homeObjTwo}></InfoSection>
            <InfoSection {...homeObjThree}></InfoSection>
            <InfoSection {...homeObjFour}></InfoSection>
        </>)
}