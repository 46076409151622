import React, { useState, useMemo } from 'react';
import Pagination from './pagination/Pagination';
import CardItem from './CardItem';
import data from './data/img-data.json';
import './Cards.css';
import Modal from './Modal/Modal';

let PageSize = 12;

function Cards() {
    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);
    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const showVideo = (url) => {
        setVideoUrl('<video autoplay style="object-fit: cover; width: auto; height: auto; max-width: 90vw; max-height: 90vh; position: fixed; z-index: 1"  controls><source src="' + url + '" type="video/mp4">Your browser does not support the video tag.</video>');

        setShowModal(true);
    };
    return (
        <div className='cards'>
            <div className='cards__container'>
                {showModal && (
                    <Modal onCloseRequest={() => setShowModal(false)}>
                        <div className={"video-container"} dangerouslySetInnerHTML={{ __html: videoUrl }} />
                    </Modal>
                )}
                <Pagination
                    className="pagination-bar-top"
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
                <h2>Items with<i class="far fa-play-circle" />: preview available</h2>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        {currentTableData.map(item => {
                            return (
                                <CardItem
                                    src={item.img_src}
                                    trailer={item.preview}
                                    premium={item.premium_only}
                                    onClick={item.preview_url ? () => showVideo(item.preview_url) : () => { }}
                                />
                            );
                        })}
                    </ul>
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    )
}

export default Cards
